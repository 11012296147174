import React, { useEffect } from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import slugify from "slugify"
import { graphql, Link } from "gatsby"
// import loadable from "@loadable/component"

import { makeStyles, useTheme } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Container from "@material-ui/core/Container"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import Popper from "@material-ui/core/Popper"
import Fade from "@material-ui/core/Fade"
import Paper from "@material-ui/core/Paper"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Alert from "@material-ui/lab/Alert"
import Snackbar from "@material-ui/core/Snackbar"
import Avatar from "@material-ui/core/Avatar"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import TextField from "@material-ui/core/TextField"
// import Skeleton from "@material-ui/lab/Skeleton"

import CloseIcon from "@material-ui/icons/Close"
import FacebookIcon from "@material-ui/icons/Facebook"
import TwitterIcon from "@material-ui/icons/Twitter"
import RedditIcon from "@material-ui/icons/Reddit"
import LinkIcon from "@material-ui/icons/Link"
import { FiShare } from "react-icons/fi"

// import CommentSection from "../../components/CommentModule"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import headshot from "../../assets/images/headshot.jpg"

import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader"
import Button from "@material-ui/core/Button"
deckDeckGoHighlightElement()

const primary = "#26a27b"

const useStyles = makeStyles((theme) => ({
  outerCtr: {
    paddingTop: "2em",
  },
  paper: {
    borderRadius: 4,
    padding: theme.spacing(1),
    inset: `0px auto auto 0px`,
    backgroundColor: theme.palette.background.paper,
    boxShadow: `rgba(230,230,230,1) 0px 1px 4px`,
  },
  postDate: {
    color: "#757575",
    fontSize: ".865rem",
    fontWeight: 400,
    fontFamily: "Open Sans",
  },
  postTitle: {
    color: "#292929",
    fontSize: "2.25rem",
    lineHeight: "1.25em",
    fontFamily: "Open Sans",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.375em",
      lineHeight: "1.35em",
    },
  },
  postShortTitle: {
    paddingTop: ".75em",
    paddingBottom: "1em",
    fontWeight: 400,
    fontFamily: "Open Sans",
    color: "#757575",
    fontSize: "1.25rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  postImage: {},
  postImgSubtitle: {
    fontWeight: 700,
    fontSize: ".8125rem",
    color: "#757575",
  },
  postBody: {
    paddingTop: "1.5em",
    color: "#292929",
    fontSize: "1.2rem",
    lineHeight: "2rem",
    fontFamily: "Open Sans",
    fontWeight: 400,
    "& iframe": {
      marginTop: "1.5em",
    },
    "& deckgo-highlight-code": {
      fontSize: ".8rem",
      lineHeight: "24px",
    },
    "& code": {
      backgroundColor: "#a4ecd6",
    },
    "& hr": {
      margin: "2em 0",
    },
    "& em": {
      fontFamily: "Open Sans",
    },
    "& blockquote": {
      background: "#f9f9f9",
      borderLeft: "7px solid #ccc",
      margin: "2em 10px",
      padding: ".5em 10px",
      "& p": {
        display: "inline",
        fontFamily: "Open Sans",
      },
      "&:before": {
        color: "#ccc",
        content: "open-quote",
        fontSize: "2em",
        lineHeight: ".1em",
        marginRight: ".15em",
        verticalAlign: "0em",
      },
    },
    "& a": {
      textDecoration: "underline",
    },
    "& img": {
      maxWidth: "-webkit-fill-available",
      display: "flex",
      marginLeft: "auto",
      marginRight: "auto",
    },
    "& > p:not(:first-child)": {
      marginTop: "2em",
    },
    "& strong": {
      color: primary,
      fontFamily: "Open Sans",
    },
    "& ul": {
      "& li": {
        listStyleType: "square",
      },
      "& li::marker": {
        color: primary,
        fontSize: "1.5rem",
      },
      "& > li:not(:first-child)": {
        marginTop: "2em",
        // "& :nth-child(1)": {
        //   marginTop: 50,
        // },
      },
    },
    "& ol": {
      "& li": {
        // listStyleType: "square",
        marginTop: "1em",
        fontWeight: 500,
      },
      "& li::marker": {
        color: primary,
        fontSize: "1.5rem",
        marginRight: 10,
        fontWeight: 900,
      },
      "& > li:not(:first-child)": {
        marginTop: "1em",
      },
    },
    "& > h2, h1, h3": {
      fontFamily: "Open Sans",
      marginTop: "2em",
      borderLeft: `4px solid ${primary}`,
      paddingLeft: 20,
    },
  },
  postAuthorAvatar: {
    marginRight: 10,
    width: theme.spacing(4),
    height: theme.spacing(4),
    border: `1px solid ${primary}`,
  },
  postAuthorAvatarMd: {
    marginRight: 10,
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    border: `1px solid ${primary}`,
  },
  postAuthor: {
    fontSize: ".875rem",
    fontWeight: 400,
    fontFamily: "Open Sans",
    lineHeight: "20px",
    letterSpacing: "-0.2px",
    marginRight: 10,
  },
  shareArticleText: {
    fontWeight: 500,
    fontFamily: "Open Sans",
    fontSize: ".95rem",
    cursor: "pointer",
    verticalAlign: "-webkit-baseline-middle",
  },
  followBtn: {
    borderRadius: "99em",
    backgroundColor: primary,
    color: "white",
    fontWeight: 400,
    textTransform: "capitalize",
    marginLeft: 15,
    fontSize: ".95rem",
    padding: "7px 16px 9px",
    "&:hover": {
      backgroundColor: "#1b5e20",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  followBtnSm: {
    borderRadius: "99em",
    backgroundColor: primary,
    color: "white",
    fontWeight: 400,
    textTransform: "capitalize",
    marginTop: 10,
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: ".95rem",
    padding: "4px 5px 4px",
    "&:hover": {
      backgroundColor: "#1b5e20",
    },
  },
  moreFromAuthorText: {
    fontSize: "1.25rem",
    fontWeight: 500,
  },
  blogDescriptionText: {
    color: "#757575",
    fontSize: ".875rem",
    fontWeight: 400,
  },
  authorCard: {
    marginLeft: ".5em",
    padding: "1em 1em",
    maxWidth: 350,
  },
}))

export default function BlogTemplate({ data }) {
  const classes = useStyles()
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [anchorElTwo, setAnchorElTwo] = React.useState(null)
  const [anchorElThree, setAnchorElThree] = React.useState(null)
  const [alertOpen, setAlertOpen] = React.useState(false)
  const [newsletterOpen, setNewsletterOpen] = React.useState(false)
  const [guestAuthor, setGuestAuthor] = React.useState(null)
  const {
    title,
    shortTitle,
    seoTitle,
    // featured,
    createdAt,
    // updatedAt,
    blogCreatedAt,
    blogUpdatedAt,
    tags,
    // body: { body },
    image,
  } = data.contentfulPressRelease
  const { author } = data.site.siteMetadata
  const body = data.contentfulPressRelease.body.childMarkdownRemark.html
  const pathToImage = getImage(image)

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))

  const {
    allContentfulPressRelease: { nodes: posts },
  } = data

  useEffect(() => {
    // tags && console.log(tags.tags.includes("ai"))
    tags && tags.tags.includes("ai")
      ? setGuestAuthor(true)
      : setGuestAuthor(null)
  }, [tags])

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const handleClickTwo = (event) => {
    setAnchorElTwo(anchorElTwo ? null : event.currentTarget)
  }
  const handleClickThree = (event) => {
    setAnchorElThree(anchorElThree ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const id = open ? "transitions-popper" : undefined

  const openTwo = Boolean(anchorElTwo)
  const idTwo = openTwo ? "transitions-popper" : undefined

  const openThree = Boolean(anchorElThree)
  const idThree = openThree ? "transitions-popper" : undefined

  const handleShare = (e) => {
    e.preventDefault()

    switch (e.currentTarget.id) {
      case "facebook":
        let followurlf = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`

        window.open(`${followurlf}`, `_blank`)
        break
      case "twitter":
        let followurlt = `https://twitter.com/intent/tweet?text=${`Check out this article -  via Snappy Web Design `}&url=${
          window.location.href
        }`
        window.open(`${followurlt}`, `_blank`)
        break
      case "reddit":
        let encodedUrl = encodeURIComponent(window.location.href)
        let followurl = `http://www.reddit.com/submit?url=${encodedUrl}&title=${encodeURIComponent(
          "Article from Snappy Web Design"
        )}`
        window.open(`${followurl}`, `_blank`)
        break
      case "copy":
        navigator.clipboard.writeText(window.location.href)
        setAlertOpen(true)
        setAnchorEl(false)
        setAnchorElTwo(false)
        break
      default:
        break
    }
  }
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setAlertOpen(false)
  }

  // function addNoFollowsToExternal(html) {
  //   var whiteList = ["([^/]+.)?snappywebdesign.net"]
  //   var str =
  //     '(<as*(?!.*\brel=)[^>]*)(href="https?://)((?!(?:' +
  //     whiteList.join("|") +
  //     '))[^"]+)"((?!.*\brel=)[^>]*)(?:[^>]*)>'

  //   return html.replace(
  //     new RegExp(str, "igm"),
  //     '$1$2$3"$4 rel="nofollow noreferrer">'
  //   )
  // }

  return (
    <Layout>
      <Seo
        title={seoTitle || title}
        article={true}
        image={`https://${image.gatsbyImageData.images.sources[0].srcSet
          .split("?")[0]
          .substring(2)}`}
        description={shortTitle}
        blogCreatedAt={blogCreatedAt}
        blogUpdatedAt={blogUpdatedAt}
      />

      <Container style={{ maxWidth: 680 }}>
        <Grid
          container
          justify="center"
          style={{ paddingTop: "4em", paddingBottom: "6.25em" }}
        >
          <Grid item xs={12}>
            <Grid container direction="column">
              <Grid item xs={12}>
                <Typography variant="h1" className={classes.postTitle}>
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.postShortTitle}>
                  {shortTitle}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ height: "50%" }}>
                <Grid container direction="row">
                  <Grid item xs={11} sm={6}>
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item>
                        <Avatar
                          alt={
                            guestAuthor
                              ? "Guest Author Profile Pic"
                              : "Profile Pic of Snappy Web Design"
                          }
                          src={guestAuthor ? undefined : headshot}
                          className={classes.postAuthorAvatar}
                          component={Link}
                          to="/about/"
                        />
                      </Grid>
                      <Grid item onMouseLeave={() => setAnchorElThree(null)}>
                        <Typography
                          variant="body1"
                          className={classes.postAuthor}
                          component={Link}
                          to="/about/"
                          onMouseOver={handleClickThree}
                        >
                          {guestAuthor ? "Guest" : author}
                        </Typography>
                        <Popper
                          id={idThree}
                          open={openThree}
                          anchorEl={anchorElThree}
                          placement="right"
                          transition
                        >
                          {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                              <Paper className={classes.authorCard}>
                                <Grid container direction="row">
                                  <Grid item xs={12}>
                                    <Grid
                                      container
                                      direction="row"
                                      justify="flex-start"
                                    >
                                      <Grid item xs={2}>
                                        <Avatar
                                          alt={
                                            guestAuthor
                                              ? "Guest Author Profile Pic"
                                              : "Profile Pic of Snappy Web Design"
                                          }
                                          src={
                                            guestAuthor ? undefined : headshot
                                          }
                                          className={classes.postAuthorAvatarMd}
                                          component={Link}
                                          to="/about/"
                                        />
                                      </Grid>
                                      <Grid item xs={10}>
                                        <Typography
                                          variant="h5"
                                          style={{
                                            fontWeight: 500,
                                            fontSize: "1.45rem",
                                            marginTop: 5,
                                          }}
                                        >
                                          {guestAuthor ? "Guest" : author}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        fontSize: ".8rem",
                                        color: "rgba(0,0,0,1)",
                                        paddingTop: ".6em",
                                      }}
                                    >
                                      {guestAuthor
                                        ? "This post was submitted by a guest author and published through our partner program"
                                        : `I taught myself how to code in fifth grade
                                      as a hobby interest. I returned to coding
                                      in 2019 with the goal of developing a
                                      skillset that would aid me in designing &
                                      creating solutions to unique problems that
                                      could arise in my career`}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    {!guestAuthor && (
                                      <Button
                                        size="small"
                                        variant="contained"
                                        className={classes.followBtnSm}
                                        onClick={() => setNewsletterOpen(true)}
                                      >
                                        Follow
                                      </Button>
                                    )}
                                  </Grid>
                                </Grid>
                              </Paper>
                            </Fade>
                          )}
                        </Popper>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="body1"
                          className={classes.postDate}
                        >
                          {createdAt} ·{" "}
                          {Math.round(body.split(" ").length / 200)} min read
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} sm={6}>
                    <Grid
                      container
                      direction="row-reverse"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <ClickAwayListener onClickAway={() => setAnchorEl(false)}>
                        <Grid item>
                          <FiShare
                            onClick={handleClick}
                            size={20}
                            style={{
                              color: "rgba(41,41,41,1)",
                              strokeWidth: 1.5,
                              cursor: "pointer",
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          />

                          <Popper
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            transition
                            placement="top"
                          >
                            {({ TransitionProps }) => (
                              <Fade {...TransitionProps} timeout={350}>
                                <List dense={true} className={classes.paper}>
                                  <ListItem
                                    button
                                    style={{ paddingTop: ".75em" }}
                                    id="facebook"
                                    onClick={handleShare}
                                  >
                                    <ListItemIcon>
                                      <FacebookIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Facebook" />
                                  </ListItem>
                                  <ListItem
                                    button
                                    style={{ paddingTop: ".75em" }}
                                    id="twitter"
                                    onClick={handleShare}
                                  >
                                    <ListItemIcon>
                                      <TwitterIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Twitter" />
                                  </ListItem>
                                  <ListItem
                                    button
                                    style={{ paddingTop: ".75em" }}
                                    id="reddit"
                                    onClick={handleShare}
                                  >
                                    <ListItemIcon>
                                      <RedditIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Reddit" />
                                  </ListItem>
                                  {!matchesXS && (
                                    <ListItem
                                      button
                                      style={{ paddingTop: ".75em" }}
                                      id="copy"
                                      onClick={handleShare}
                                    >
                                      <ListItemIcon>
                                        <LinkIcon />
                                      </ListItemIcon>
                                      <ListItemText primary="Copy Link" />
                                    </ListItem>
                                  )}
                                </List>
                              </Fade>
                            )}
                          </Popper>
                        </Grid>
                      </ClickAwayListener>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="column">
                  <Grid item xs={12}>
                    <GatsbyImage
                      image={pathToImage}
                      imgClassName={classes.postImage}
                      alt={title}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {pathToImage.title && (
                      <Typography
                        className={classes.postImgSubtitle}
                        align="center"
                        variant="subtitle2"
                      >
                        {pathToImage.title}
                      </Typography>
                    )}
                  </Grid>
                  {pathToImage.description && (
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle2"
                        align="center"
                        className={classes.postImgSubtitle}
                      >
                        {pathToImage.description}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div
                  variant="body1"
                  className={classes.postBody}
                  dangerouslySetInnerHTML={{
                    __html: body,
                  }}
                  // dangerouslySetInnerHTML={{
                  //   __html: addNoFollowsToExternal(body),
                  // }}
                ></div>
              </Grid>

              <Grid item xs={12} style={{ marginTop: "2em" }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <ClickAwayListener
                        onClickAway={() => setAnchorElTwo(false)}
                      >
                        <Grid item>
                          <FiShare
                            onClick={handleClickTwo}
                            size={20}
                            style={{
                              color: "rgba(41,41,41,1)",
                              strokeWidth: 1.5,
                              cursor: "pointer",
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          />
                          <span
                            className={classes.shareArticleText}
                            onClick={handleClickTwo}
                            aria-hidden="true"
                          >
                            Share Article ·{" "}
                          </span>
                          <Link
                            className={classes.shareArticleText}
                            style={{
                              color: `${primary}`,
                              fontWeight: 500,
                              textDecoration: "underline",
                            }}
                            to="/contact/"
                          >
                            Hire me
                          </Link>
                          <Popper
                            id={idTwo}
                            open={openTwo}
                            anchorEl={anchorElTwo}
                            transition
                            placement="bottom"
                          >
                            {({ TransitionProps }) => (
                              <Fade {...TransitionProps} timeout={350}>
                                <List dense={true} className={classes.paper}>
                                  <ListItem
                                    button
                                    style={{ paddingTop: ".75em" }}
                                    id="facebook"
                                    onClick={handleShare}
                                  >
                                    <ListItemIcon>
                                      <FacebookIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Facebook" />
                                  </ListItem>
                                  <ListItem
                                    button
                                    style={{ paddingTop: ".75em" }}
                                    id="twitter"
                                    onClick={handleShare}
                                  >
                                    <ListItemIcon>
                                      <TwitterIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Twitter" />
                                  </ListItem>
                                  <ListItem
                                    button
                                    style={{ paddingTop: ".75em" }}
                                    id="reddit"
                                    onClick={handleShare}
                                  >
                                    <ListItemIcon>
                                      <RedditIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Reddit" />
                                  </ListItem>
                                  <ListItem
                                    button
                                    style={{ paddingTop: ".75em" }}
                                    id="copy"
                                    onClick={handleShare}
                                  >
                                    <ListItemIcon>
                                      <LinkIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Copy Link" />
                                  </ListItem>
                                </List>
                              </Fade>
                            )}
                          </Popper>
                        </Grid>
                      </ClickAwayListener>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* <CommentSection /> */}
            </Grid>
            {/* <Divider
              style={{ marginTop: "3.125em", marginBottom: "3.125em" }}
            /> */}
          </Grid>
        </Grid>
      </Container>
      <Container
        style={{ backgroundColor: "rgba(250,250,250,1)" }}
        maxWidth={false}
      >
        <Grid item xs={12}>
          <Container style={{ maxWidth: 680 }}>
            <Grid container direction="row" style={{ paddingTop: "2em" }}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={10}>
                    <Typography
                      variant="h6"
                      className={classes.moreFromAuthorText}
                    >
                      More from {author}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      size="small"
                      variant="contained"
                      className={classes.followBtn}
                      onClick={() => setNewsletterOpen(true)}
                    >
                      Follow
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={10}>
                    <Typography
                      variant="body1"
                      className={classes.blogDescriptionText}
                    >
                      Subscribe to the Snappy Web Design Blog to get notified
                      when a new post goes live. We'll never share your e-mail
                      address or use it for any reason other than to share new
                      posts.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid item xs={12}>
          <Container style={{ maxWidth: 680 }}>
            <Grid
              container
              justify="center"
              style={{ paddingTop: "6.25em", paddingBottom: "6.25em" }}
            >
              {posts.map((post) => {
                const image = getImage(post.image)
                const slug = slugify(post.title, { lower: true })

                return (
                  <Grid item xs={12} key={post.id}>
                    <Grid container direction="column">
                      <Grid item xs={12}>
                        <Typography
                          variant="body1"
                          className={classes.postDate}
                          style={{ marginBottom: ".75em" }}
                        >
                          Published {post.createdAt}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Link
                          to={`/press/${slug}/`}
                          style={{ textDecoration: "none" }}
                        >
                          <Typography
                            variant="h6"
                            className={classes.postTitle}
                          >
                            {post.title}
                          </Typography>
                        </Link>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="body1"
                          className={classes.postShortTitle}
                        >
                          {post.shortTitle}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container direction="column">
                          <Grid item xs={12}>
                            <Link
                              to={`/press/${slug}/`}
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              <GatsbyImage
                                image={image}
                                imgClassName={classes.postImage}
                                alt={post.title}
                              />
                            </Link>
                          </Grid>
                          <Grid item xs={12}>
                            {post.image.title && (
                              <Typography
                                className={classes.postImgSubtitle}
                                align="center"
                                variant="subtitle2"
                              >
                                {post.image.title}
                              </Typography>
                            )}
                          </Grid>
                          {post.image.description && (
                            <Grid item xs={12}>
                              <Typography
                                variant="subtitle2"
                                align="center"
                                className={classes.postImgSubtitle}
                              >
                                {post.image.description}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="body1"
                          className={classes.postBody}
                          dangerouslySetInnerHTML={{
                            __html:
                              post.body.childMarkdownRemark.html.substring(
                                0,
                                500
                              ) + "...",
                          }}
                        ></Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Link
                          to={`/press/${slug}/`}
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          <Typography
                            variant="body1"
                            className={classes.postFooterLink}
                          >
                            Read more ·{" "}
                            {Math.round(post.body.body.split(" ").length / 200)}{" "}
                            minute read
                          </Typography>
                        </Link>
                      </Grid>
                    </Grid>
                    <Divider
                      style={{ marginTop: "3.125em", marginBottom: "3.125em" }}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </Container>
        </Grid>
      </Container>
      <Dialog
        open={newsletterOpen}
        onClose={() => setNewsletterOpen(false)}
        aria-labelledby="newsletter-form-dialog"
        fullScreen={fullScreen}
      >
        <form name="newsletter" method="POST" netlify data-netlify="true">
          <input type="hidden" name="form-name" value="newsletter" />
          <DialogTitle
            id="newsletter-form-title"
            style={{
              marginTop: "0%",
              marginLeft: "auto",
              marginRight: "auto",
              color: theme.palette.common.accent,
            }}
          >
            Subscribe
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => setNewsletterOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ textAlign: "center" }}>
              To subscribe to this website, please enter your email address
              here. We will send updates occasionally.
            </DialogContentText>
            <TextField
              margin="dense"
              id="name"
              name="email"
              label="Email Address"
              type="email"
              fullWidth
            />
          </DialogContent>
          <DialogActions
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: matchesXS ? "80%" : undefined,
            }}
          >
            <Button
              onClick={() => setNewsletterOpen(false)}
              size="large"
              style={{
                color: theme.palette.common.accent,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => setNewsletterOpen(false)}
              style={{ color: theme.palette.common.accent }}
              as="button"
              type="submit"
            >
              Subscribe
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar open={alertOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          style={{ backgroundColor: `${primary}` }}
        >
          Link copied
        </Alert>
      </Snackbar>
    </Layout>
  )
}
export const query = graphql`
  query getSinglePress($title: String) {
    contentfulPressRelease(title: { eq: $title }) {
      title
      seoTitle
      shortTitle
      featured
      createdAt(formatString: "MMM D")
      updatedAt(formatString: "MMM D")
      tags {
        tags
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      image {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          width: 680
          quality: 95
        )
      }
    }
    site {
      siteMetadata {
        author
      }
    }
    allContentfulPressRelease(
      filter: { title: { ne: $title }, tags: { tags: { nin: "ai" } } }
      limit: 3
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        id
        title
        shortTitle
        createdAt(formatString: "MMMM d")
        updatedAt(formatString: "MMMM d")
        body {
          body
          childMarkdownRemark {
            html
          }
        }
        tags {
          tags
        }
        image {
          gatsbyImageData(layout: CONSTRAINED, width: 700, placeholder: BLURRED)
          title
          description
        }
      }
    }
  }
`
